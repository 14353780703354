import React from 'react';
import {
  Affix, Layout, Row, Col,
} from 'antd';
import FeatherIcon from 'feather-icons-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory } from '@reach/router';
import ThinkBrightLogo from '../../../assets/thinkbright-logo.svg';
import style from './sidebar.module.less';
import { useWindowSize } from '../../../utils/hooks';

const { Content } = Layout;

const DomContent = () => (
  <aside>
    <ThinkBrightLogo className={style.thinkBrightLogo} />
    <div className={style.profileAvatar} />
    <div className={`${style.name} centerAlign`}>
      <div className={`${style.boxName} centerAlign`}>
        <h2>
          Gerald Drausinger
        </h2>
      </div>
      <div>
        IT- und Unternehmensberater
      </div>
      <ul className={`box ${style.badge} contactBlock`}>
        <li className={`${style.contactBlockItem}`}>
          <a
            href="mailto:&#111;&#102;&#102;&#105;&#99;&#101;&#64;&#116;&#104;&#105;&#110;&#107;&#98;&#114;&#105;&#103;&#104;&#116;&#46;&#97;&#116;"
            target="_top"
          >
            <span>
              <FeatherIcon size="19" icon="mail" />
              &nbsp;
              <span className={style.emailHider}>@</span>
            </span>
          </a>
        </li>
        <li className={`${style.contactBlockItem}`}>
          <a
            href="tel:&#43;&#52;&#51;&#54;&#55;&#48;&#54;&#48;&#55;&#56;&#49;&#49;&#49;"
            target="_top"
          >
            <span>
              <FeatherIcon size="19" icon="smartphone" />
            </span>
            &nbsp;
            +43 670 607 8111
          </a>
        </li>
      </ul>
    </div>
  </aside>
);

const Sidebar = (props) => {
  const [width] = useWindowSize();
  const { children } = props;
  const { pathname } = globalHistory.location;
  let domContent = <DomContent />;
  if (width > 997) {
    domContent = (
      <Affix offsetTop={0}>
        <DomContent />
      </Affix>
    );
  }
  if (width < 768) {
    domContent = <></>;
    if (pathname === '/') {
      domContent = <DomContent />;
    }
  }
  return (
    <>
      <Layout>
        <Content className={`${style.content} ${style.background}`}>
          <Row>
            <Col sm={24} md={9} lg={6} className={style.sidebarContent}>
              { domContent }
            </Col>
            <Col sm={24} md={15} lg={18}>
              <Layout className={`${style.background} ${style.boxContent} borderRadiusSection`}>
                { children }
              </Layout>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export const Sidebar404 = (props) => {
  const { children } = props;
  return (
    <Layout>
      <Content className={`${style.content} ${style.background} `}>
        <Row>
          <Col sm={24} md={24} lg={24}>
            <Layout className={`${style.background} ${style.boxContent} ${style.sideBar404Radius}`}>
              {children}
            </Layout>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Sidebar;
